import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Skeleton, Spin } from 'antd';
import { ConversationViewerBlockProps } from '../Blocks/ConversationViewer';
import { ConversationsWrapperProps, ConversationTabWrapperProps } from '../Common';
import { ItemRowMetadataProps } from '../Components/ItemRowMetadata/ItemRowMetadata';
import { ClientDashboardTabExtensionProps } from './ClientDashboardTabExtension';
import { CommentsTabExtensionProps } from './CommentsTabExtension';
import { ConversationsTileExtensionProps } from './ConversationsTileExtension';

const LazyConversationTabWrapper = React.lazy(
	() => import('../Common/ConversationTabWrapper')
);
export const AsyncLazyConversationTabWrapper: React.FC<
	ConversationTabWrapperProps
> = props => {
	return (
		<React.Suspense
			fallback={<Spin style={{ paddingBottom: sizes.XS, paddingRight: sizes.MD }} />}
		>
			<LazyConversationTabWrapper {...props} />
		</React.Suspense>
	);
};

const LazyConversationsWrapper = React.lazy(
	() => import('../Common/ConversationsWrapper')
);
export const AsyncLazyConversationsWrapper: React.FC<
	ConversationsWrapperProps
> = props => {
	return (
		<React.Suspense fallback={<Skeleton active />}>
			<LazyConversationsWrapper {...props} />
		</React.Suspense>
	);
};

const LazyDemoPage = React.lazy(() => import('../Common/Development/DemoPage'));
export const AsyncLazyDemoPage: React.FC<{ piletApi: PiletApi }> = ({ piletApi }) => {
	return (
		<React.Suspense fallback={<Skeleton active />}>
			<LazyDemoPage piletApi={piletApi} />
		</React.Suspense>
	);
};

const LazyConversationViewerBlock = React.lazy(
	() => import('../Blocks/ConversationViewer')
);
export const AsyncLazyConversationViewerBlock: React.FC<
	ConversationViewerBlockProps
> = props => {
	return (
		<React.Suspense fallback={<Skeleton active />}>
			<LazyConversationViewerBlock {...props} />
		</React.Suspense>
	);
};

const LazyItemRowMetadata = React.lazy(
	() => import('../Components/ItemRowMetadata/ItemRowMetadata')
);
export const AsyncLazyItemRowMetadata: React.FC<ItemRowMetadataProps> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyItemRowMetadata {...props} />
		</React.Suspense>
	);
};

const LazyConversationsTileExtension = React.lazy(
	() => import('./ConversationsTileExtension')
);
export const AsyncLazyConversationsTileExtension: React.FC<
	ConversationsTileExtensionProps
> = props => {
	return (
		<React.Suspense fallback={<Skeleton active />}>
			<LazyConversationsTileExtension {...props} />
		</React.Suspense>
	);
};

const LazyClientDashboardTabExtension = React.lazy(
	() => import('./ClientDashboardTabExtension')
);
export const AsyncLazyClientDashboardTabExtension: React.FC<
	ClientDashboardTabExtensionProps
> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyClientDashboardTabExtension {...props} />
		</React.Suspense>
	);
};

const LazyCommentTabExtension = React.lazy(() => import('./CommentsTabExtension'));
export const AsyncLazyCommentsTabExtension: React.FC<
	CommentsTabExtensionProps
> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyCommentTabExtension {...props} />
		</React.Suspense>
	);
};
