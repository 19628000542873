import { UpdatePosition } from './Common';
import {
	AddResourceRequestDto,
	BulkMetadataResponse,
	CreateMessageRequestDto,
	DeleteMessageRequestDto,
	EditMessageRequestDto,
	GetConversationPermissionsResponse,
	GetMessagesResponseDto,
	GetMetadataResponse,
	MessageDto,
} from './Common/types';

interface Configuration {
	origin: string;
	getAccessToken?: () => Promise<string>;
}

const configuration: Configuration = {
	origin: window.origin + '/io/conversations',
};

export function configureConversationHttp(configure: (current: Configuration) => void) {
	configure(configuration);
}

export async function createRequest(path: string, init: RequestInit = {}) {
	let url = path;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	if (!(url.startsWith('http') || url.startsWith('//')) && configuration.origin) {
		const normalizedBase = configuration.origin.endsWith('/')
			? configuration.origin.slice(0, -1)
			: configuration.origin;
		const normalizedPath = !url.startsWith('/') ? '/' + url : url;
		url = normalizedBase + normalizedPath;
	}

	if (!init.headers?.hasOwnProperty('authorization') && configuration.getAccessToken) {
		const accessToken = await configuration.getAccessToken();
		const headersWithAuth: HeadersInit = {
			...init.headers,
			authorization: `Bearer ${accessToken}`,
		};
		init.headers = headersWithAuth;
	}
	return new Request(url, init);
}

export const ConversationHttp = {
	async sampleRequest() {
		const endpoint = new URL(`${configuration.origin}/sample`);
		const request = await createRequest(endpoint.toString(), {
			method: 'GET',
		});

		const response = await fetch(request);
		if (response.status !== 200) {
			throw new Error('could not call sample endpoint');
		}
	},

	async getMessagesByURN(urn: string, isTile: boolean, pagination?: boolean) {
		if (!urn) {
			throw new Error('Missing resource urn for getMessagesByURN!');
		}
		const endpoint = new URL(`${configuration.origin}/api/Messages`);
		endpoint.searchParams.append('Resource', urn);
		if (isTile) {
			endpoint.searchParams.append('IgnoreMarkRead', 'true');
		}
		if (pagination) {
			endpoint.searchParams.append('Latest', 'true');
			endpoint.searchParams.append('Count', '10');
		}

		const request = await createRequest(endpoint.toString(), { method: 'GET' });
		const response = await fetch(request);

		if (response.status !== 200) {
			throw new Error(
				`could not get messages - response: ${response.status}, ${response.statusText}`
			);
		}

		const responseText = await response.text();
		const responseJSON = JSON.parse(responseText, dateReviver);

		return responseJSON as GetMessagesResponseDto;
	},

	async getMetadataByURN(urn: string) {
		if (!urn) {
			throw new Error('Missing resource urn for getMetadataByURN!');
		}
		const endpoint = new URL(`${configuration.origin}/api/Metadata`);
		endpoint.searchParams.append('Resource', urn);

		const request = await createRequest(endpoint.toString(), { method: 'GET' });
		const response = await fetch(request);

		if (response.status !== 200) {
			throw new Error('could not get metadata');
		}

		const responseText = await response.text();
		const responseJSON = JSON.parse(responseText);

		return responseJSON as GetMetadataResponse;
	},

	async getBulkMetadata(urn: string) {
		if (!urn) {
			throw new Error('Missing resource urn for bulk metadata!');
		}

		const endpoint = new URL(`${configuration.origin}/api/Metadata/Bulk`);
		endpoint.searchParams.append('Resource', urn);

		const request = await createRequest(endpoint.toString(), { method: 'GET' });
		const response = await fetch(request);

		if (response.status !== 200) {
			throw new Error('could not get bulk metadata');
		}

		const responseText = await response.text();
		const responseJSON = JSON.parse(responseText);

		return responseJSON as BulkMetadataResponse;
	},

	async createMessage(payload: Partial<CreateMessageRequestDto>) {
		const request = await createRequest('/api/Messages', {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetch(request);
		if (response.status !== 200) {
			throw new Error('could not create message');
		}

		const responseText = await response.text();
		return JSON.parse(responseText, dateReviver) as MessageDto;
	},

	async editMessage(payload: EditMessageRequestDto) {
		const request = await createRequest('/api/Messages', {
			method: 'PUT',
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetch(request);
		if (response.status !== 200) {
			throw new Error('could not edit message');
		}

		const responseText = await response.text();
		return JSON.parse(responseText, dateReviver) as MessageDto;
	},

	async deleteMessage(payload: DeleteMessageRequestDto) {
		const request = await createRequest('/api/Messages', {
			method: 'DELETE',
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetch(request);
		if (response.status !== 204) {
			throw new Error('could not delete message');
		}
	},

	async addResource(payload: AddResourceRequestDto) {
		const request = await createRequest('/api/resourcemanager/resources', {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetch(request);
		if (response.status !== 200) {
			throw new Error('could not add resource');
		}
	},

	async checkConversationPermissions(urn: string, userId: string) {
		if (!urn) {
			throw new Error('Missing resource urn!');
		}
		const endpoint = new URL(`${configuration.origin}/api/Permissions`);
		endpoint.searchParams.append('Resource', urn);

		const request = await createRequest(endpoint.toString(), { method: 'GET' });
		const response = await fetch(request);

		if (response.status !== 200) {
			throw new Error(`could not check permissions for user ${userId}`);
		}

		const responseText = await response.text();
		return JSON.parse(responseText) as GetConversationPermissionsResponse;
	},

	async getUpdates(
		urn: string,
		isTile: boolean,
		messageId: string,
		updatePostion: UpdatePosition
	) {
		if (!urn) {
			throw new Error('Missing resource urn!');
		}

		const endpoint = new URL(`${configuration.origin}/api/Messages`);
		endpoint.searchParams.append('Resource', urn);

		if (updatePostion === 'After') {
			endpoint.searchParams.append('After', messageId);
		}

		if (updatePostion === 'Before') {
			endpoint.searchParams.append('Before', messageId);
			endpoint.searchParams.append('Count', '10');
		}

		if (isTile) {
			endpoint.searchParams.append('IgnoreMarkRead', 'true');
		}

		const request = await createRequest(endpoint.toString(), { method: 'GET' });
		const response = await fetch(request);

		if (response.status !== 200) {
			throw new Error(
				`could not get updates - response: ${response.status}, ${response.statusText}`
			);
		}

		const responseText = await response.text();
		const responseJSON = JSON.parse(responseText, dateReviver);

		return responseJSON as GetMessagesResponseDto;
	},
};

function dateReviver(_key: string, value: string) {
	if (typeof value === 'string') {
		if (
			/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/.test(
				value
			)
		) {
			return new Date(value);
		}
	}
	return value;
}
